const DescAgence = () => {
    return(
        <div className="descriptif min-[320px]:py-8 xl:py-32 min-[320px]:pr-4 xl:pr-8 xl:ml-8 min-[320px]:pl-4">
            <div className="descriptif-txt flex flex-col justify-around min-[320px]:pr-0 xl:pr-32">
                <div className="descriptif-txt-1">
                    <h1>L'agence<span>,</span></h1>
                    <p>
                        Focus Production est spécialisée dans la production de contenus vidéo et photo. Nous vous accompagnons dans la création : de l'idée jusqu'à la réalisation, Focus Production s'occupe de tout.
                        <br/><br/>
                        Notre objectif ? Innover. Surprendre. Convaincre.
                        <br/><br/>
                        Que vous soyez une marque, une agence, une entreprise, ou tout simplement un particulier, Focus Production trouve avec vous la solution sur-mesure, adaptée à vos besoins.
                        Nous réalisons le projet le plus adapté à votre demande grâce à un accompagnement personnalisé.
                        <br/> <br/>
                        Capturer l’instant, partager l’émotion ! Voilà ce que nous vous proposons.

                    </p>
                </div>

                <div className="descriptif-txt-2 self-end">
                    <h2>Simon Freche<span>.</span></h2>
                    <p>Passionné d'image depuis l'adolescence, je me suis naturellement orienté vers un parcours audiovisuelle.
                        A peine sorti d'étude de réalisation vidéo je décide d'ouvrir ma propre société de production vidéo et photo
                        Focus Production a 4 ans, 4 ans que je vis mon rêve d'enfant éveillé.
                    </p>
                    <div className="round-bg"></div>
                </div>
            </div>
            <div className="descriptif-img">
                <img alt="CEO de l'entreprise" src={require("../../img/AGENCE.jpg")}/>
            </div>
        </div>
    )
}
export default DescAgence;