import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';  // Importez useNavigate ici

const Realisations = () => {
    const navigate = useNavigate();  // Déclarez useNavigate ici

    const [tabList, setTabList] = useState([
        { id: 1, title: 'Voir tout', slug:'' },
        { id: 3, title: 'Evenementiel', slug: 'evenementiel' },
        { id: 4, title: 'Corporate', slug:'corporate' },
        { id: 5, title: 'Publicitaire', slug:'publicitaire'},
    ]);
    const [activeTab, setActiveTab] = useState();
    const [content, setContent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const modalRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            let response;
            if (activeTab === 0) {
                response = await axios.get(`https://back.focus-production.fr/api/projets?populate=*&sort=Ordre_affichage:asc`, {
                    headers: {
                        Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
                    }
                });
            } else {
                const categorySlug = tabList[activeTab].slug;
                response = await axios.get(`https://back.focus-production.fr/api/projets?populate=*&filters[categories_de_projets][category_slug][$contains]=${categorySlug}&sort=Ordre_affichage:asc`, {
                    headers: {
                        Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
                    }
                });
            }
            setContent(response.data);
        };
        fetchData();
    }, [activeTab]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tabIdFromUrl = urlParams.get('tab');
        const tabIndex = tabList.findIndex(tab => slugify(tab.title) === tabIdFromUrl);
        setActiveTab(tabIndex !== -1 ? tabIndex : 0);
    }, [tabList]);

    const handleClick = (index) => {
        setActiveTab(index);
        const tabId = slugify(tabList[index].title);
        navigate(`?tab=${tabId}`, { replace: true });
    };

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const handleCloseModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
            handlePause();
        }
    };

    const handlePause = () => {
        if (playerRef.current) {
            const iframe = playerRef.current;
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
    };

    const handleModalCloseButtonClick = () => {
        setShowModal(false);

        // Pause the video when the modal close button is clicked
        if (playerRef.current) {
            playerRef.current.pause();
        }
    };
    const slugify = (text) => {
        return text
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    };

    return (
        <div className="min-[320px]:py-0 xl:py-32  reals">
            <h1 className=" min-[320px]:px-4 xl:px-0 reals-title min-[320px]:text-left xl:text-center min-[320px]:my-4 xl:my-12 min-[320px]:pb-8 font-bold min-[320px]:text-36 xl:text-title-49">Nos Réalisations</h1>
            <ul className=" min-[320px]:px-4 xl:px-0 flex justify-between min-[320px]:w-full xl:w-6/12 m-auto">
                {tabList.map((tab, index) => (
                    <li
                        id={slugify(tab.title)}
                        key={tab.id}
                        onClick={() => handleClick(index)}
                        className={activeTab === index ? 'active' : ''}
                    >
                        {tab.title}
                    </li>
                ))}
            </ul>
            <div className=" m-8 xl:mt-8 xl:m-16 reals-content">
                {content && content?.data.map((project) => (
                    <div id={`real-` + project.id} key={project.id} onClick={() => handleProjectClick(project)}>
                        <img src={project?.attributes.url_miniature}/>
                        <h2 className="mt-2">{project.attributes.titre}</h2>
                    </div>
                ))}
            </div>
            {selectedProject && (
                <div className={`modal ${showModal ? 'open' : ''}`} onClick={handleCloseModal}>
                    <div className="modal-content" ref={modalRef}>
                        <button onClick={handleModalCloseButtonClick} className="showreel-modal-close"><img src={require('../img/close-icon.png')}/></button>
                        {selectedProject.attributes.vimeo_video_url && (
                            <iframe ref={playerRef} className="iframe-projets" src={`https://player.vimeo.com/video/${selectedProject.attributes.vimeo_video_url}`}>
                            </iframe>
                        )}

                        {selectedProject.attributes.youtube_video_url && !selectedProject.attributes.vimeo_video_url && (
                            <iframe ref={playerRef} className="iframe-projets" src={`https://www.youtube.com/embed/${selectedProject.attributes.youtube_video_url}`}>
                            </iframe>
                        )}

                        <button onClick={handlePause}>fermer</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Realisations;
