import DescAgence from "../components/Agence/DescAgence";
import ChiffresAgence from "../components/Agence/ChiffresAgence";
import PhotoVideo from "../components/Home/PhotoVideo";
import BackstagesAgence from "../components/Agence/BackstagesAgence";
import FormHome from "../components/FormHome";
import Avis from "../components/Home/Avis";
import AvisAgence from "../components/Home/AvisAgence";

const Agence = () => {


    return(
        <>
            <DescAgence/>
            <ChiffresAgence/>


            <div className="mt-32">
                <AvisAgence/>
            </div>

            <div className="pt-32 pb-8 min-[320px]:pt-12">
                <PhotoVideo/>
            </div>

            <div className="xl:px-16 min-[320px]:px-4">
                <BackstagesAgence/>
            </div>

            <FormHome/>

        </>
    )
}

export default Agence