import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {Route, Routes} from "react-router-dom";
import React from "react";
import Home from "./pages/Home";
import Photographie from "./pages/Photographie";
import VideoPub from "./pages/VideoPub";
import Voyages from "./pages/Voyages";
import Realisations from "./pages/Realisations";
import Contact from "./pages/Contact";
import Agence from "./pages/Agence";
import VideoEvent from "./pages/VideoEvent";
import VideosCorpo from "./pages/VideoCorpo";
import MentionsLegales from "./pages/MentionsLegales";
import RedirectMail from "./pages/RredirectMail";
import Maintenance from "./pages/Maintenance";

function App() {
  return (
    <div className="App">

      <Header/>
      <Routes>
        {/* <Route path="/" element={<Maintenance/>}/>; */}
        <Route path="/" element={<Home/>}/>
        <Route path="/agence" element={<Agence />} />
        <Route path="/video/publicitaire" element={<VideoPub />} />
        <Route path="/video/evenementielle" element={<VideoEvent />} />
        <Route path="/video/corporate" element={<VideosCorpo />} />
        <Route path="/photographie" element={<Photographie />} />
        <Route path="/voyages" element={<Voyages />} />
        <Route path="/realisations" element={<Realisations />} />
        <Route path="/mentions-legales" element={<MentionsLegales/>}/>
        <Route path="/redirect-mail" element={<RedirectMail/>}/>
      </Routes>
        <Footer/>
    </div>
  );
}

export default App;
