import { motion, useMotionValue, useTransform } from "framer-motion";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

function FormHome() {
    const x = useMotionValue(0);
    const y = useMotionValue(0);

    const translateX = useTransform(y, [-400, 400], [45, -45]);
    const translateY = useTransform(x, [-400, 400], [-45, 45]);

    function handleMouse(event) {
        const rect = event.currentTarget.getBoundingClientRect();

        x.set(event.clientX - rect.left);
        y.set(event.clientY - rect.top);
    }

    const form = useRef();

    const formIsValid = () => {
        const emailInput = form.current.querySelector('input[name="user_email"]');
        const objectInput = form.current.querySelector('input[name="user_object"]');
        const messageInput = form.current.querySelector('textarea[name="user_message"]');

        if (!emailInput.value || !objectInput.value || !messageInput.value) {
            alert('Certains champs ne sont pas remplis');
            return false;
        }
        return true;
    };

    const sendEmail = (event) => {
        event.preventDefault(); // Prevent form submission
        const captchaValue = event.target.value; // Assuming captcha value is passed as argument
        if (!formIsValid()) {
            alert('Veuillez remplir correctement le formulaire');
            return;
        }

        emailjs.sendForm('service_hfw5q1n', 'template_pq38x5h', form.current, {
            publicKey: 'pxzM2QhJILfVFJfCf',
        }).then(
            () => {
                window.location.href = '/redirect-mail';
            },
            (error) => {
                alert('ECHEC DE L\'ENVOI...', error.text);
            },
        );
    };

    return (
        <motion.div id="contact" className="formhome min-[320px]:p-6 xl:p-16 grid min-[320px]:grid-cols-1 xl:grid-cols-2" onMouseMove={(event) => handleMouse(event)}>
            <div className="formhome-title min-[320px]:w-full xl:w-3/5">
                <p className="formhome-left-content min-[320px]:text-f-40 xl:text-title-xl">
                    Une idée ? <br />
                    Un projet ? <br />
                    Un message ? <br />
                </p>
                <motion.div
                    style={{
                        width: 200,
                        height: 200,
                        borderRadius: 200,
                        backgroundColor: "#fff",
                        position: "absolute",
                        top: -20,
                        left: 200,
                        zIndex: -1,
                        translateX: translateX,
                        translateY: translateY,
                        className: "ball-form"
                    }}
                />
                <p className="formhome-text">Contactez-nous et discutons de votre prochain projet.</p>
            </div>

            <div className="formhome-form">
                <form ref={form} onSubmit={sendEmail}>
                    <div>
                        <input type="email" required placeholder="Email*" name="user_email" />
                    </div>

                    <div>
                        <input type="text" required placeholder="Objet*" name="user_object" />
                    </div>

                    <div>
                        <textarea rows="12" required placeholder="Bonjour, je vous contacte car..." name="user_message" />
                    </div>

                    {/* <ReCAPTCHA
                        sitekey="6LcitYgpAAAAADjeR8-4MnxOdprdVbPrVCZuQg2d"
                        onChange={captchaValue => sendEmail(captchaValue)}
                    />*/}
                    <input type="submit" value="Envoyer" className="btnform btn-blackarrow" />
                    {/*<button className="btn-blackarrow" type="submit">Envoyer</button> */}
                </form>
            </div>
        </motion.div>
    )
}

export default FormHome;