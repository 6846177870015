import { HashLink as Link } from 'react-router-hash-link';
const PhotoVideo = () => {
    return(

        <div className="container-photovideo min-[320px]:px-4 xl:px-16 min-[320px]:mb-8 xl:mb-32 flex justify-between xl:flex-row min-[320px]:flex-col min-[320px]:mt-16 xl:mt-0">
            <div className="video">
                <h2 className="title-video_menu"><a href="/video/publicitaire">VIDEO</a></h2>
                <ul>
                    <li className="text-xl"><a href="/video/corporate" className="link_menu">Corporate</a></li>
                    <li className="text-xl"><a href="/video/publicitaire" className="link_menu">Publicitaire</a></li>
                    <li className="text-xl"><a href="/video/evenementiel" className="link_menu">Evenementiel</a></li>
                </ul>
            </div>
            <div className="photo">
                <h2><a href="/photographie">PHOTO</a></h2>
                <ul>
                    <li className="text-xl"><Link to="/photographie#portraits" className="link_menu">Portraits</Link></li>
                    <li className="text-xl"><Link to="/photographie#corporate" className="link_menu">Corporate</Link></li>
                    <li className="text-xl"><Link to="/photographie#evenements" className="link_menu">Evenements</Link></li>
                    <li className="text-xl"><a href="/voyages" className="link_menu">Voyages</a></li>
                </ul>
            </div>
        </div>
    )
}

export default PhotoVideo