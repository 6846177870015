import React, {useRef, useEffect} from "react";



function EtapesProjet() {

   /* const handleScroll = (event) => {
        const container = event.target;
        const scrollAmount = event.deltaY;
        container.scrollTo({
            top: 0,
            left: container.scrollLeft + scrollAmount,
            behavior: 'smooth'
        });
    };*/


    return(
        <div className="pl-8 xl:pl-32 pb-16 etapes pt-8 horizontal-section etapes-scroll">
            {/* <section className="slide-content">
                <img src={require('../img/ corporate-1.png')} alt="" />
            <div className="content-slide">
            <h2>PRODUIT 1</h2>
            <a href="/produit-1">Voir la carte</a>
            </div>
            </section>

                <section className="slide-content">
                    <img src={require('../img/ corporate-1.png')} alt="" />
                    <div className="content-slide">
                        <h2>PRODUIT 1</h2>
                        <a href="/produit-1">Voir la carte</a>
                    </div>
                </section>

                <section className="slide-content">
                    <img src={require('../img/ corporate-1.png')} alt="" />
                    <div className="content-slide">
                        <h2>PRODUIT 1</h2>
                        <a href="/produit-1">Voir la carte</a>
                    </div>
                </section>

                <section className="slide-content">
                    <img src={require('../img/ corporate-1.png')} alt="" />
                    <div className="content-slide">
                        <h2>PRODUIT 1</h2>
                        <a href="/produit-1">Voir la carte</a>
                    </div>
                </section>*/}







  <h2 className="font-bold text-f-40">Etapes du projet<span>.</span></h2>
            <div className="horizontal-scroll-container">
                <div id="scrolldiv" className="etapes-cardcontainer" >
                    <div className="card">
                        <span>1</span>
                        <div>
                            <h3>Brief</h3>
                        <p>La première étape consiste toujours par une phase d’écoute et d’échanges afin de comprendre vos besoins, votre marché, vos réalités, votre vision, vos enjeux.
                            Tous ces éléments permettront de réaliser un devis personnalisé et adapté à votre demande.
                        </p>
                        </div>
                    </div>

                    <div className="card">
                        <span>2</span>
                        <div>
                            <h3>Écriture</h3>
                        <p>Écriture en fonction de vos objectifs et attentes, quel format de vidéo utiliser pour répondre aux objectifs marketing et communication
                            Nous accordons une importance particulière à la phase de conception qui initie chacun de nos projets et qui définit l’orientation des vidéos.
                        </p>
                        </div>
                    </div>

                    <div className="card">
                        <span>3</span>
                        <div>
                            <h3>Planning</h3>
                        <p>Établir un calendrier et un découpage, ces documents permettront de communiquer clairement sur toute la production de la vidéo tout en respectant les deadlines de production et de diffusion.</p>
                        </div>
                    </div>
                    <div className="card">
                        <span>4</span>
                        <div>
                            <h3>Tournage</h3>
                        <p>Cette étape consiste à enregistrer les images et les sons nécessaires pour la vidéo. Il peut s'agir d'images fixes ou de mouvements, de prises de vue avec une caméra ou en drone.</p>
                        </div>
                    </div>
                    <div className="card">
                        <span>5</span>
                        <div>
                            <h3>Montage</h3>
                        <p>Pendant cette étape, la vidéo est montée. Cela inclut l'ajout de motion design et titrages, l'étalonnage, le mastering audio, et l'exportation dans les formats de diffusion appropriés.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EtapesProjet