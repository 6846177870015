import MenuPhoto from "../components/MenuPhoto";
import Portraits from "../components/Photographie/Portraits";
import Corporate from "../components/Photographie/Corporate";
import Evenements from "../components/Photographie/Evenements";
import FormHome from "../components/FormHome";
import {useEffect, useState} from "react";

const Photographie = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Ajouter un gestionnaire d'événements de défilement pour afficher ou masquer le bouton
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const triggerScroll = 200; // Altitude à partir de laquelle le bouton devient visible

            setIsVisible(scrollY > triggerScroll);
        };

        // Ajouter le gestionnaire d'événements à la fenêtre lors de l'initialisation
        window.addEventListener('scroll', handleScroll);

        // Nettoyer le gestionnaire d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Fait défiler en douceur vers le haut
        });
    };


    return(
        <>
        <MenuPhoto/>
        <div className="min-[320px]:px-4 xl:px-16 min-[320px]:pt-14 xl:pt-28">
            <Portraits/>

            <Corporate/>

            <Evenements/>
        </div>
            <FormHome/>
            {isVisible && (
                <button className="ct-btt" onClick={scrollToTop} style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }}>
                    <img className="icon-btt" src={require('../img/arrow-black-focus.png')} alt=""/>
                </button>
            )}
        </>
    )
}

export default Photographie