import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import LogoBlack from "../img/logo-focus-noir.svg"
import LogoWhite from "../img/logo-focus-white.svg"
import CloseIcon from "../img/close-menu-icon.svg"
import OpenIcon from "../img/menu-icon.svg"

const Header = () => {

    const [open, setOpen] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const [handleArrow, setHandleArrow] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
        setHandleArrow(!handleArrow);
    }
    const openVideoMobile = () => {
        setOpenVideo(!openVideo)
        setIsVisible(!isVisible)
    }
    const handleOpenMobile = () => {
        setOpenMobile(!openMobile)
        document.body.classList.toggle('lock');
    }

    const [corpomenu, setCorpomenu] = useState(false);
    const [eventmenu, setEventmenu] = useState(false);
    const [pubmenu, setPubmenu] = useState(false);

    const [agencemenu, setAgencemenu] = useState(false);
    const [videomenu, setVideomenu] = useState(false);
    const [realsmenu, setRealsmenu] = useState(false);
    const [photomenu, setPhotomenu] = useState(false);
    const [voyagesmenu, setVoyagesmenu] = useState(false);

    useEffect(() => {
        const url = window.location.href;
        const Corporate = 'corporate';
        const Event = 'evenementiel';
        const Pub = 'publicitaire';
        const agence = 'agence';
        const video = 'video';
        const reals = 'realisations';
        const photo = 'photographie';
        const voyages = 'voyages';

        if (url.includes(Corporate)) {
            setCorpomenu(true);
        }
        if (url.includes(Event)) {
            setEventmenu(true);
        }
        if (url.includes(Pub)) {
            setPubmenu(true);
        }

        if (url.includes(agence)){
            setAgencemenu(true);
        }
        if (url.includes(video)){
            setVideomenu(true);
        }
        if (url.includes(reals)){
            setRealsmenu(true);
        }
        if (url.includes(photo)){
            setPhotomenu(true);
        }
        if (url.includes(voyages)){
            setVoyagesmenu(true);
        }
    }, []);

    function refreshPage() {
        window.location.reload(true);
    }

    function useScrollDirection() {
        const [scrollDirection, setScrollDirection] = useState(null);

        useEffect(() => {
            let lastScrollY = window.pageYOffset;

            const updateScrollDirection = () => {
                const scrollY = window.pageYOffset;
                const direction = scrollY > lastScrollY ? "down" : "up";
                if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                    setScrollDirection(direction);
                }
                lastScrollY = scrollY > 0 ? scrollY : 0;

                const divElement = document.getElementById("header");
                if (scrollY > 0) {
                    divElement.style.background = "#212121"; // Remplacer "red" par la couleur souhaitée
                } else {
                    divElement.style.background = "transparent"; // Remplacer "none" par le style de fond par défaut
                }
            };
            window.addEventListener("scroll", updateScrollDirection); // add event listener
            return () => {
                window.removeEventListener("scroll", updateScrollDirection); // clean up
            }
        }, [scrollDirection]);

        return scrollDirection;
    }
    const scrollDirection = useScrollDirection();

    console.log(agencemenu);

    return(
        <>
            <div id="header" className={`header fixed ${scrollDirection === "down" ? "-top-32" : "top-0"} ${scrollDirection === "up" ? "bg-dark" : "bg-transparent"} z-50 h-28 w-screen flex justify-between px-8 transition-all duration-500`}>
                <div className="container-logo container-logo flex justify-center items-center">
                    <a href="/"><img className="w-24" alt="Logo de l'entreprise" src={require("../img/focus-white.png")}/></a>
                </div>
                <nav className="navigation flex justify-center items-center">
                    <ul>
                        <a className={`font-normal mx-8 text-lg ${agencemenu ? 'active' : ''}`} href="/agence" >L'agence</a>
                        <div className="container-btn-video"><p className={`btn-video font-normal mx-8 text-lg ${videomenu ? 'active' : ''} ${handleArrow ? 'arrow-up' : 'arrow-down'}`} onClick={handleOpen}>Vidéo</p>
                            {open ? <nav className="mobile-menu">
                                <li className="mobile-menu-item"><a className={corpomenu ? 'active' : ''} href="/video/corporate">Corporate</a></li>
                                <li className="mobile-menu-item"><a className={pubmenu ? 'active' : ''} href="/video/publicitaire">Publicitaire</a></li>
                                <li className="mobile-menu-item"><a className={eventmenu ? 'active' : ''} href="/video/evenementielle">Évènementielle</a></li>
                            </nav> : ''}
                        </div>
                        <a className={`font-normal mx-8 text-lg ${realsmenu ? 'active' : ''}`} href="/realisations">Réalisations</a>
                        <a className={`font-normal mx-8 text-lg ${photomenu ? 'active' : ''}`} href="/photographie">Photographie</a>
                        <a className={`font-normal mx-8 text-lg ${voyagesmenu ? 'active' : ''}`} href="/voyages">Voyages</a>
                        <a href="/#contact" className="font-medium mx-8 text-lg btn-redarrow">Travaillons ensemble</a>
                    </ul>
                </nav>


            </div>

            <div className="menu-mobile">
                <div className="menu-mobile-header flex justify-between">
                    <a href="/"> <img src={LogoWhite} alt="Logo Focus Prod en tout noir"/></a>
                    <img src={OpenIcon} alt="Icone de fermeture de menu mobile" onClick={handleOpenMobile}/>
                </div>

                {openMobile ? <nav className="navigation-mobile">
                    <div className="navigation-mobile-header flex justify-between items-center">
                        <a href="/"> <img src={LogoBlack} alt="Logo Focus Prod en tout noir"/></a>
                        <img src={CloseIcon} alt="Icone de fermeture de menu mobile" onClick={handleOpenMobile}/>
                    </div>
                    <div className="navigation-mobile-links flex flex-col">
                        <a className={`font-normal mx-8 text-lg ${agencemenu ? 'active' : ''}`} href="/agence">L'agence</a>
                        <div className="container-btn-video py-2"><p className={`btn-video mx-8 font-normal text-lg ${videomenu ? 'active' : ''}`} onClick={openVideoMobile}>Vidéo</p>
                            {openVideo ? <nav className={`mobile-menu fade-animation ${isVisible ? "fade-in" : "fade-out"}`}>
                                <li className="mobile-menu-item"><a className={corpomenu ? 'active' : ''} href="/video/corporate">Corporate</a></li>
                                <li className="mobile-menu-item"><a className={pubmenu ? 'active' : ''} href="/video/publicitaire">Publicitaire</a></li>
                                <li className="mobile-menu-item"><a className={eventmenu ? 'active' : ''} href="/video/evenementielle">Évènementielle</a></li>
                            </nav> : ''}
                        </div>
                        <a className={`font-normal mx-8 text-lg ${realsmenu ? 'active' : ''}`} href="/realisations">Réalisations</a>
                        <a className={`font-normal mx-8 text-lg ${photomenu ? 'active' : ''}`} href="/photographie">Photographie</a>
                        <a className={`font-normal mx-8 text-lg ${voyagesmenu ? 'active' : ''}`} href="/voyages">Voyages</a>
                        <a className="font-medium mx-8 text-lg btn-redarrow" href="/#contact">Travaillons ensemble</a>
                    </div>
                </nav> : '' }
            </div>
        </>

    )
}

export default Header