import React, { useState, useEffect, useRef } from "react";

const ProjetsHome = () => {
    const [projets, setProjets] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const modalRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        fetch("https://back.focus-production.fr/api/projets?populate=*&filters[affichage_front_home][$eq]=true", {
            headers: {
                Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
            }
        })
            .then((res) => res.json())
            .then((data) => setProjets(data));
    }, []);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const handleCloseModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
            handlePause();
        }
    };

    const handlePause = () => {
        if (playerRef.current) {
            const iframe = playerRef.current;
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
    };

    const handleModalCloseButtonClick = () => {
        setShowModal(false);

        // Pause the video when the modal close button is clicked
        if (playerRef.current) {
            playerRef.current.pause();
        }
    };

    return (
        <div className="container-projetshome xl:p-28 min-[320px]:p-4 min-[320px]:py-20 flex flex-col justify-center items-center">
            <div className="flex justify-center items-center flex-col">
                <h2 className="xl:text-5xl xl:pb-28 min-[320px]:pb-9 font-bold min-[320px]:text-center min-[320px]:text-4xl"> Nos projets<span>.</span></h2>
                <a className="link-real self-end btn-arrow" href="/realisations">Voir toutes les réalisations</a>
                <div className="container-projects">
                    {projets.data?.slice(0, 4).map((projet) => (
                        <div className="card-projet relative" onClick={() => handleProjectClick(projet)} key={projet.id}>
                            <img alt="cover du projet" src={projet.attributes.url_miniature} />
                            <h2 className="absolute text-3xl bottom-6 px-4">{projet.attributes.titre}</h2>
                        </div>
                    ))}
                </div>
                {selectedProject && (
                    <div className={`modal ${showModal ? 'open' : ''}`} onClick={handleCloseModal}>
                        <div className="modal-content" ref={modalRef}>
                            <button onClick={handleModalCloseButtonClick} className="showreel-modal-close"><img src={require('../../img/close-icon.png')}/></button>
                            {selectedProject.attributes.vimeo_video_url && (
                                <iframe ref={playerRef} className="iframe-projets" src={`https://player.vimeo.com/video/${selectedProject.attributes.vimeo_video_url}`}>
                                </iframe>
                            )}

                            {selectedProject.attributes.youtube_video_url && !selectedProject.attributes.vimeo_video_url && (
                                <iframe ref={playerRef} className="iframe-projets" src={`https://www.youtube.com/embed/${selectedProject.attributes.youtube_video_url}`}>
                                </iframe>
                            )}

                            <button onClick={handlePause}>fermer</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProjetsHome;
