import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faInstagram, faLinkedinIn, faVimeoV} from "@fortawesome/free-brands-svg-icons";
import SubFooter from "./SubFooter";
import { HashLink as Link } from 'react-router-hash-link';

const Footer = () => {

    return(
        <>
        <div className="container-footer min-[320px]:p-6 xl:p-16">
            <div className="infos-boite">
                <h4 className="text-2xl">Focus Production</h4>
                <ul>
                    <li>

                        <a target="_blank" href="https://maps.app.goo.gl/KezxY7upJ6ebmVPWA">29 Rue Lucie Aubrac, 33300 Bordeaux</a>
                    </li>
                    <li>
                        <a href="mailto:contact.focus.production@gmail.com">contact.focus.production@gmail.com</a>
                    </li>
                    <li>
                        <a href="tel:0786113896">07 86 11 38 96</a>
                    </li>
                </ul>
            </div>

            <div className="menu-footer">
                <h4 className="text-2xl">Menu</h4>
                <ul>
                    <li><a href="/">Accueil</a></li>
                    <li><a href="/video/corporate">Vidéos</a></li>
                    <li><a href="/photographie">Photographie</a></li>
                    <li><a href="/realisations">Réalisations</a></li>
                    <li><Link to="/#contact">Travaillons ensemble</Link></li>
                    <li><a href="/mentions-legales">Mentions légales</a></li>
                </ul>
            </div>
            <div className="rs">
                <h4 className="text-2xl">Suivez-nous sur</h4>
                <div className="reseaux w-1/2">
                    <ul className="flex justify-between">
                        <li><a target="_blank" href="https://instagram.com/simon.freche"><FontAwesomeIcon className="mr-2 icon-footer" icon={faInstagram} />instagram</a></li>
                        <li><a target="_blank" href="https://www.linkedin.com/company/focus-production/"> <FontAwesomeIcon className="mr-2 icon-footer" icon={faLinkedinIn} />linkedin</a></li>
                    </ul>
                </div>
            </div>
        </div>
    <SubFooter/>
    </>
    )
}
export default Footer