import React, {useEffect, useRef, useState} from 'react';
import FormHome from "../components/FormHome";
import EtapesProjet from "../components/EtapesProjet";
import {useParams} from "react-router-dom"



const VideosPub = () => {

    const slug = useParams();
    console.log(slug)

    const [sectionProjets, setSectionProjets] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const modalRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        // Fonction pour récupérer les données
        const fetchData = () => {
            fetch("https://back.focus-production.fr/api/projets?populate=*&filters[categories_de_projets][category_slug][$contains]=publicitaire&filters[affichage_page_type_de_video]=true", {
                headers: {
                    Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
                }
            })
                .then((res) => res.json())
                .then((data) => setSectionProjets(data));
        };

        // Récupération des données au chargement
        fetchData();

        // Rafraîchissement toutes les 5 minutes
        const interval = setInterval(() => {
            fetchData();
        }, 5 * 60 * 1000); // 5 minutes en millisecondes

        // Nettoyage de l'intervalle lorsque le composant est démonté
        return () => clearInterval(interval);
    }, []);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const handleCloseModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
            handlePause();
        }
    };

    const handlePause = () => {
        if (playerRef.current) {
            const iframe = playerRef.current;
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
    };
    const handleModalCloseButtonClick = () => {
        setShowModal(false);

        // Pause the video when the modal close button is clicked
        if (playerRef.current) {
            playerRef.current.pause();
        }
    };


    return(
        <>
            <div className="pb-32 pt-16 xl:pt-64 videos vertical-section">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-14 mx-8 xl:mx-32">
                    <div className="publicitaires">
                        <h1 className="title-vdo">Vidéos publicitaires</h1>
                        <p>Une vidéo publicitaire ou de présentation de produit est un moyen efficace et engageant de communiquer avec vos cibles. Elle permet de mettre en contexte l'utilisation de vos produits ou services, en montrant leur fonctionnement, leur processus de fabrication, et leur utilisation finale.
                        </p>
                    </div>
                    <div className="publicitaires">
                        <div className="line"></div>
                        <p>Nous comprenons l'importance de la vidéo publicitaire pour votre entreprise, c'est pourquoi nous travaillons avec vous pour créer une vidéo dynamique et engageante qui met en valeur votre savoir-faire. La vidéo publicitaire peut également mettre en avant l'expérience de vos clients afin qu'ils se projettent au cœur de votre établissement.
                        </p>
                    </div>
                </div>

                <div className="lesplus mx-16 xl:mx-32 mt-20 gap-x-20">
                    <div className="leplus-matos">
                        <h3>Matériel professionnel</h3>
                    </div>

                    <div className="leplus-plus">
                        <h3>Un + pour votre entreprise</h3>
                    </div>

                    <div className="leplus-bdx">
                        <h3>Proximité: <span>On est Bordelais !</span></h3>
                    </div>
                </div>

            </div>

            <EtapesProjet/>

            <div className="autres px-8 xl:px-32 py-16">
                <h2 className="font-bold text-f-40">Quelques réalisations<span>.</span></h2>
                <div className="autres-videos mt-8">
                    <div className="autres-videos-api">
                        {sectionProjets.data?.slice(0, 2).map((projet) => (
                            <div className="card" onClick={() => handleProjectClick(projet)}>
                                <img alt={projet?.attributes.titre_portrait} src={projet?.attributes?.url_miniature}/>
                                <h3 className="mt-2">{projet.attributes.titre}</h3>
                            </div>
                        ))}
                    </div>

                    {selectedProject && (
                        <div className={`modal ${showModal ? 'open' : ''}`} onClick={handleCloseModal}>
                            <div className="modal-content" ref={modalRef}>
                                <button onClick={handleModalCloseButtonClick} className="showreel-modal-close"><img src={require('../img/close-icon.png')}/></button>

                                {selectedProject.attributes.vimeo_video_url && (
                                    <iframe ref={playerRef} className="iframe-projets" src={`https://player.vimeo.com/video/${selectedProject.attributes.vimeo_video_url}`}>
                                    </iframe>
                                )}

                                {selectedProject.attributes.youtube_video_url && !selectedProject.attributes.vimeo_video_url && (
                                    <iframe ref={playerRef} className="iframe-projets" src={`https://www.youtube.com/embed/${selectedProject.attributes.youtube_video_url}`}>
                                    </iframe>
                                )}

                            </div>
                        </div>
                    )}

                    <div className="autres-videos-bloc">
                        <p>Envie d'en voir plus ? </p>
                        <a href="/realisations?tab=publicitaire">Voir les réalisations</a>
                    </div>


                </div>
            </div>

            <FormHome/>


    </>

    )
}

export default VideosPub