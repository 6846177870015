import {useState, useEffect} from "react";
import CountUp from 'react-countup';

const ChiffresAgence = () => {

    const [chiffres, setChiffres] = useState([]);
    useEffect(() => {
        fetch("https://back.focus-production.fr/api/chiffres?populate=*", {
            headers: {
                Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
            }
        })
            .then((res) => res.json())
            .then((data) => setChiffres(data));
    }, []);

    if(chiffres.length < 1) return <div></div>



    return(
        <div className="chiffres px-8 py-16">
            <h2 className="min-[320px]:text-36 xl:text-title-49 font-bold">Pourquoi travailler ensemble ?</h2>
            <div className="chiffres-chiffre min-[320px]:grid-cols-1 grid xl:grid-cols-3 py-8">

                <div className="flex flex-col justify-center">
                    <p><CountUp enableScrollSpy={true} end={chiffres?.data[0].attributes.chiffre_un}/></p>
                    <p>{chiffres?.data[0]?.attributes.label_chiffre_un}</p>
                </div>

                <div className="flex flex-col justify-center">
                    <p><CountUp enableScrollSpy={true} end={chiffres?.data[0].attributes.chiffre_deux}/></p>
                    <p>{chiffres?.data[0]?.attributes.label_chiffre_deux}</p>
                </div>

                <div className="flex flex-col justify-center">
                    <p><CountUp enableScrollSpy={true} end={chiffres?.data[0]?.attributes.chiffre_trois}/></p>
                    <p>{chiffres?.data[0]?.attributes.label_chiffre_trois}</p>
                </div>

            </div>



        </div>
    )
}

export default ChiffresAgence