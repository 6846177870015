import {useEffect, useState} from "react";
import {Box, ImageList, ImageListItem} from "@mui/material";

const Evenements = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [evenements, setEvenements] = useState([]);
    const [firstEvents, setFirstEvents] = useState([]);
    useEffect(() => {
        fetch("https://back.focus-production.fr/api/evenements?populate=*&sort=ordre_affichage:asc", { headers: {
                Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
            }})
            .then((res) => res.json())
            .then((data) => setEvenements(data));
    }, []);
    useEffect(() => {
        fetch("https://back.focus-production.fr/api/evenements?populate=*&filters[affichage_first][$eq]=true&sort=ordre_affichage:asc", { headers: {
                Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
            }})
            .then((res) => res.json())
            .then((data) => setFirstEvents(data));
    }, []);


    const toggleDiv = () => {
        setIsVisible(!isVisible);
    };



    return(
        <div id="evenements" className="portraits pb-32">
            <h2 className="portraits-title font-bold min-[320px]:text-f-24 xl:text-f-64">Evenements<span>,</span></h2>
            <p className="portraits-text min-[320px]:w-full xl:w-1/3 min-[320px]:text-16 xl:text-f-18">Réalisez des photos qui reflètent votre personnalité, vos émotions, seul ou à plusieurs</p>


            <div className="portraits-container min-[320px]:mt-4 xl:mt-9">
                <div className="flex mb-8 min-[320px]:gap-5 xl:gap-0 min-[320px]:flex-col xl:flex-row">
                    {firstEvents?.data?.slice(0, 2).map((evenements) => (
                        <div className="flex-1 portraits-container-img min-[320px]:mr-0 xl:mr-8">
                            <img className="object-cover h-full" alt={evenements.attributes?.titre_portrait} src={evenements?.attributes?.url_image}/>
                        </div>
                    ))}

                    <div className="flex-1 portraits-container-img red-background min-[320px]:w-full xl:w-1/3 flex flex-col justify-between p-6">
                        <p className="text-center">Immortalisez l’instant</p>
                        <button onClick={toggleDiv} className="self-end text-f-22 red-background-link">{isVisible ? <span className="see-less">Voir moins</span>  : <span className="see-more">Voir plus</span>}</button>
                    </div>
                </div>


                <div className={`fade-animation ${isVisible ? "fade-in" : "fade-out"}`}>
                    <div className={`${isVisible ? "fade-in" : "fade-out"}`}   style={{ display: isVisible ? "block" : "none" }}>
                        <Box>
                            <ImageList variant="masonry" cols={3} gap={30} sx={{cols:1}}>
                                {evenements?.data?.slice(2, 50).map((evenements) => (
                                    <ImageListItem key={evenements.id}>
                                        <img alt={evenements?.attributes.titre_portrait} src={evenements?.attributes?.url_image}/>
                                    </ImageListItem>
                                ))};
                            </ImageList>
                        </Box>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default Evenements;