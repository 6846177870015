const SubFooter = () => {
    return(
    <div className="subfooter xl:px-16 min-[320px]:py-6 min-[320px]:px-6 xl:py-8 flex justify-between items-end">
        <div className="subfooter-made">
        <p>Made with ❤️ by</p>
            <li>
                Justine Magry
            </li>
            <li>
                Arnaud Bouteyre
            </li>
        </div>
        <div className="subfooter-line-logo mr-6 flex flex-col items-end">
            <div className="logo mb-6">
                <img alt="Logo de Focus Production" src={require('../img/logofocus.png')}/>
            </div>
            <div className="line">

            </div>
        </div>

    </div>
    )
}

export default SubFooter;
