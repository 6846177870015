import React, {useEffect, useRef, useState} from 'react';
import FormHome from "../components/FormHome";
import EtapesProjet from "../components/EtapesProjet";



const VideosCorpo = () => {

    const [sectionEvent, setSectionEvent] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const modalRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        // Fonction pour récupérer les données
        const fetchData = () => {
            fetch("https://back.focus-production.fr/api/projets?populate=*&filters[categories_de_projets][category_slug][$contains]=corporate&filters[affichage_page_type_de_video]=true", {
                    headers: {
                        Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
                    }
                }

            )
                .then((res) => res.json())
                .then((data) => setSectionEvent(data));
        };

        // Récupération des données au chargement
        fetchData();

        // Rafraîchissement toutes les 5 minutes
        const interval = setInterval(() => {
            fetchData();
        }, 5 * 60 * 1000); // 5 minutes en millisecondes

        // Nettoyage de l'intervalle lorsque le composant est démonté
        return () => clearInterval(interval);
    }, []);

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const handleCloseModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
            handlePause();
        }
    };

    const handlePause = () => {
        if (playerRef.current) {
            const iframe = playerRef.current;
            const iframeSrc = iframe.src;
            iframe.src = iframeSrc;
        }
    };
    const handleModalCloseButtonClick = () => {
        setShowModal(false);

        // Pause the video when the modal close button is clicked
        if (playerRef.current) {
            playerRef.current.pause();
        }
    };



    return(
        <>
            <div className="pb-32 pt-16 xl:pt-64 videos vertical-section">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-14 mx-8 xl:mx-32">
                    <div className="publicitaires">
                        <h1 className="title-vdo">Vidéos corporate</h1>
                        <p>Si vous cherchez à promouvoir votre entreprise ou service, rien de mieux qu'un film d'entreprise pour attirer l'attention de vos spectateurs. La clé d'une vidéo institutionnelle ou corporate réussie est le storytelling, une narration qui guide le spectateur à travers votre message et évoque une émotion pour créer de l'engagement. C'est là que Focus Production vous aide à vous démarquer, en réalisant une vidéo qui met en lumière les valeurs, services et produits de votre entreprise de manière subtile.
                        </p>
                    </div>
                    <div className="publicitaires">
                        <div className="line"></div>
                        <p>Une vidéo de présentation d'entreprise transmet les valeurs de votre entreprise en offrant un aperçu de votre univers et de votre philosophie. L'interview peut également être un format incontournable pour raconter une histoire ou présenter un service en créant une proximité avec vos clients

                        </p>
                    </div>
                </div>

                <div className="lesplus mx-16 xl:mx-32 mt-20 gap-x-20">
                    <div className="leplus-matos">
                        <h3>Matériel professionnel</h3>
                    </div>

                    <div className="leplus-plus">
                        <h3>Un + pour votre entreprise</h3>
                    </div>

                    <div className="leplus-bdx">
                        <h3>Proximité: <span>On est Bordelais !</span></h3>
                    </div>
                </div>

            </div>

            <EtapesProjet/>

            <div className="autres px-8 xl:px-32 py-16">
                <h2 className="font-bold text-f-40">Quelques réalisations<span>.</span></h2>
                <div className="autres-videos mt-8">
                    <div className="autres-videos-api">
                        {sectionEvent.data?.slice(0, 2).map((projet) => (
                            <div className="card" onClick={() => handleProjectClick(projet)}>
                                <img alt={projet?.attributes.titre_portrait} src={projet?.attributes?.url_miniature}/>
                                <h3 className="mt-2">{projet.attributes.titre}</h3>
                            </div>
                        ))}
                    </div>

                    {selectedProject && (
                        <div className={`modal ${showModal ? 'open' : ''}`} onClick={handleCloseModal}>
                            <div className="modal-content" ref={modalRef}>
                                <button onClick={handleModalCloseButtonClick} className="showreel-modal-close"><img src={require('../img/close-icon.png')}/></button>

                                {selectedProject.attributes.vimeo_video_url && (
                                    <iframe ref={playerRef} className="iframe-projets" src={`https://player.vimeo.com/video/${selectedProject.attributes.vimeo_video_url}`}>
                                    </iframe>
                                )}

                                {selectedProject.attributes.youtube_video_url && !selectedProject.attributes.vimeo_video_url && (
                                    <iframe ref={playerRef} className="iframe-projets" src={`https://www.youtube.com/embed/${selectedProject.attributes.youtube_video_url}`}>
                                    </iframe>
                                )}

                            </div>
                        </div>
                    )}

                    <div className="autres-videos-bloc">
                        <p>Envie d'en voir plus ? </p>
                        <a href="/realisations?tab=corporate">Voir les réalisations</a>
                    </div>


                </div>
            </div>

            <FormHome/>


    </>

    )
}

export default VideosCorpo