const MentionsLegales = () => {
    return(
        <>
            <div className="mentionslegales xl:mt-32 xl:mx-16 xl:mb-16 mx-4 mb-6">
                <h1>Mentions Légales</h1>
                <p>
                    Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Focus Production l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
                </p>

                <h4>Edition du site</h4>

                <p>Le présent site, accessible à l’URL www.focus-production.fr, est édité par :

                    Simon Freche, résidant 29 rue Lucie Aubrac 33300 Bordeaux, de nationalité Française (France), né(e) le 03/11/1998,
                </p>

                <h4>Hébergement</h4>

                <p>Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).</p>

                <h4>Directeur de publication</h4>

                <p>Le Directeur de la publication du Site est Simon Freche.</p>

                <h4>Nous contacter</h4>

                <p>Par téléphone: <a href="tel:+33786113896">07 86 11 38 96</a></p>

                <p>Par email: <a href="contact.focus.production@gmail.com">contact.focus.production@gmail.com</a></p>

                <p>Par courrier: 29 rue Lucie Aubrac 33300 Bordeaux</p>
            </div>
        </>
    )
}
export default MentionsLegales