import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import FormHome from "../components/FormHome";
import {ImageList, ImageListItem} from "@mui/material";

const Voyages = () => {

    const [tabs, setTabs] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [content, setContent] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const modalRef = useRef(null);

    const getApiData = async () => {
        const response = await fetch(
            "https://back.focus-production.fr/api/categories-de-voyages?populate=*", {
            headers: {
            Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
        }
            }).then((response) => response.json());

        // update the state
        setTabs(response);
    };
    useEffect(() => {
        getApiData();
    }, [activeTab]);

    const fetchData = async () => {
        const categorySlug = tabs?.data[activeTab]?.attributes.slug;
        if (activeTab === 0) {
            const response = await fetch(`https://back.focus-production.fr/api/voyages?populate=*&filters[categories_de_voyages][slug][$contains]=vietnam&sort=ordre_daffichage:asc`, {
                    headers: {
                        Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
                    }
                })
                .then((response) => response.json());
            setContent(response);
        } else{
            const response = await fetch(`https://back.focus-production.fr/api/voyages?populate=*&filters[categories_de_voyages][slug][$contains]=${categorySlug}&sort=ordre_daffichage:asc`, {
                    headers: {
                        Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
                    }
                })
                .then((response) => response.json());
            setContent(response);
        }
    };
    useEffect(() => {
        fetchData();
    },[activeTab] );
    console.log(content)

    const handleClick = (index) => {
        setActiveTab(index);
    };
    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setShowModal(true);
    };
    const handleCloseModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };



    return (
        <>
            <div className="cominsoon">
                <h1 className="">COMING SOON</h1>
            </div>
            {/*<div className="min-[320px]:py-0 xl:py-32  reals images">
                <h1 className=" min-[320px]:px-4 xl:px-0 reals-title min-[320px]:text-left xl:text-center min-[320px]:my-4 xl:my-12 min-[320px]:pb-8 font-bold min-[320px]:text-36 xl:text-title-49">Nos voyages</h1>
                <ul className=" min-[320px]:px-4 xl:px-0 flex justify-between min-[320px]:w-full xl:w-6/12 m-auto">
                    {tabs?.data?.slice(0,5).map((tab, index) => (
                        <li
                            key={tab.id}
                            onClick={() => handleClick(index)}
                            className={activeTab === index ? 'active' : ''}
                        >
                            {tab.attributes.nom_de_la_categorie}
                        </li>
                    ))}
            </ul>
                <ImageList
                    cols={2}
                    className="images-listing xl:mt-8 min-[320px]:mt-0 min-[320px]:m-0 xl:m-16">
                {content?.data?.map((project) => (
                    <ImageListItem className="reals-content" id={`real-` + project.id} key={project.id}>
                        {project.attributes.images_du_voyage.data.map((singleImage) => (
                            <div className="img">
                                <img src={singleImage.attributes.url}/>
                            </div>
                        ))}
                    </ImageListItem>
                ))}
            </ImageList>
            {selectedProject && (
                <div className={`modal ${showModal ? 'open' : ''}`} onClick={handleCloseModal}>
                    <div className="modal-content" ref={modalRef}>
                        <button onClick={() => setShowModal(false)} className="modal-close"></button>
                        //<img src={`https://localhost:1337${selectedProject.attributes.video.data.attributes.url}`}/>
                    </div>
                </div>
            )}
        </div>

            <FormHome/>*/}
            </>
    );
};

export default Voyages;