import Star from '../../img/star.svg';
const AvisAgence = () => {
    return(
        <div className="container-avis pl-16 min-[320px]:pl-4">
            <div className="container-avis-title">
                <h2 className="text-f-40 font-bold">Vos avis comptent<span>.</span></h2>
            </div>
            <div className="container-avis-blocs">
                <div className="card-avis p-6">
                    <div className="star mb-4">
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                    </div>
                    <p>J'ai eu l'occasion de travailler à plusieurs reprises avec Focus Production, le travail a toujours été extrêmement bien réalisé, avec beaucoup de soins et de professionnalisme et cela concerne l'ensemble des intervenants. Les délais ont toujours été respectés. Je recommande vivement cette entreprise qui saura vous conseiller et répondre à vos besoins pour la réalisation de vidéos professionnelles de qualité
                    </p>
                    <p className="person mt-4">Agence B52</p>
                </div>
                <div className="card-avis p-6">
                    <div className="star mb-4">
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                    </div>
                    <p>Simon est devenu un vrai partenaire de travail avec qui j’adore shooter, toujours avec de bonnes idées, de nouveaux lieux, de nouvelles prises de vues et mes photos professionnelles sont de lui ! Également monteur videaste il a su m’écouter et me créer la vidéo dont je rêvais. Un grand merci pour ton investissement👏🏼
                    </p>
                    <p className="person mt-4">Alice Varela</p>
                </div>
                <div className="card-avis p-6">
                    <div className="star mb-4">
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                    </div>
                    <p>En tant que chargée de communication au sein de l’ALGEEI, j’ai pu travailler sur différents projets vidéos avec Simon. Vidéaste à l’écoute et très professionnel. C’est toujours un bonheur et un plaisir de collaborer ensemble. Je recommande !
                    </p>
                    <p className="person mt-4">ALGEEI</p>
                </div>
            </div>
        </div>
    )
}

export default AvisAgence