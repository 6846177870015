import {Link} from "react-scroll";

const MenuPhoto = () => {
    return(
        <div className="menuphoto grid min-[320px]:grid-cols-1 xl:grid-cols-3">
            <div className="menuphoto-1">
                <Link to="portraits" offset={-100} spy={true} smooth={true} duration={500}>
                    <img src={require('../img/background-portraits.jpg')} alt="portraits"/>
                    <p className="menuphoto-1-title text-f-40">Portraits</p>
                    <a className="menuphoto-1-link  text-f-24" href="/photo">Voir les réalisations</a>
                </Link>
            </div>

            <div className="menuphoto-2">
                <Link to="corporate" offset={-100} spy={true} smooth={true} duration={500}>
                    <img src={require('../img/background-corporate.jpg')} alt="Corporate"/>
                    <p className="menuphoto-2-title text-f-40">Corporate</p>
                    <a className="menuphoto-2-link  text-f-24" href="/photo">Voir les réalisations</a>
                </Link>
            </div>

            <div className="menuphoto-3">
                <Link to="evenements" offset={-100} spy={true} smooth={true} duration={500}>
                    <img src={require('../img/background-event.jpg')} alt="Evenements"/>
                    <p className="menuphoto-3-title text-f-40">Évenements</p>
                    <a className="menuphoto-3-link text-f-24" href="/photo">Voir les réalisations</a>
                </Link>
            </div>
        </div>
    )

}
export default MenuPhoto;