import Star from '../../img/star.svg';
const Avis = () => {
    return(
        <div className="container-avis pl-16 min-[320px]:pl-4">
            <div className="container-avis-title">
                <h2 className="text-f-40 font-bold">Vos avis comptent<span>.</span></h2>
            </div>
            <div className="container-avis-blocs">
                <div className="card-avis p-6">
                    <div className="star mb-4">
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                    </div>
                    <p>J'ai collaboré plusieurs fois avec Simon depuis juin 2021. Le dernier projet en date était la captation vidéo de deux évènements en octobre 2023 pour un de nos plus importants clients et la prestation était parfaite. Sa compréhension du brief et la qualité du rendu impeccable, comme d'habitude. Merci Simon !</p>
                    <p className="person mt-4">Agence AWAM</p>
                </div>
                <div className="card-avis p-6">
                    <div className="star mb-4">
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                    </div>
                    <p>En tant qu'agence de communication, Simon est un partenaire sur lequel nous pouvons compter depuis quelques mois déjà. Professionnel, motivé et arrangeant, c'est toujours un plaisir de collaborer avec Simon !
                    </p>
                    <p className="person mt-4">Agence COM'SUD</p>
                </div>
                <div className="card-avis p-6">
                    <div className="star mb-4">
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                        <img alt="etoile des avis" src={Star}/>
                    </div>
                    <p>Nous avons eu l'occasion de rencontrer Simon de Focus production grâce à une vidéo tournée au Château Paloumey pour le site LaDegust.fr. Son professionnalisme et la grande qualité de son travail nous ont convaincus de faire appel à lui pour le lancement de notre nouvelle cuvée sans soufre ajouté. Et nous n'avons pas été déçus ! Simon est réactif, créatif, consciencieux et à l'écoute. Le résultat vidéo est superbe. A suivre…
                    </p>
                    <p className="person mt-4">Château Paloumey</p>
                </div>
            </div>
        </div>
    )
}

export default Avis