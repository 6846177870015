import React, { useRef, useEffect, useState } from "react";
import axios from "axios";

const SliderHome = () => {
    const elementRef = useRef(null);
    const elementRef2 = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const element = elementRef.current;
            const element2 = elementRef2.current;
            const scrollY = window.scrollY;

            // Calculer la valeur de déplacement en fonction du défilement de la page
            const translateX = scrollY / 2;

            // Appliquer la transformation de translation aux éléments
            element.style.transform = `translateX(${translateX}px)`;
            element2.style.transform = `translateX(-${translateX}px)`;
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [clients, setClients] = useState();
    useEffect(() => {
        const fetchData = async () => {
            let response;
            response = await axios.get(`https://back.focus-production.fr/api/clients?populate=*&sort=ordre_affichage:asc`, { headers: {
                    Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
                }});
            setClients(response.data);
        };
        fetchData();
    }, );

    return (
        <>
            <div className="slider py-12">
                <h2 className="text-title-49 pl-12 mb-16 font-bold">
                    Nos clients<span>.</span>
                </h2>
                <div className="slider-left" ref={elementRef}>
                    <div className="mask hidden">
                        {clients && clients?.data.slice(0,5).map((client) => (
                            <img src={client.attributes.url_video} alt=""/>
                            ))}
                    </div>
                </div>
                <div className="slider-right mt-8" ref={elementRef2}>
                    <div className="mask hidden">
                        {clients && clients?.data.slice(5,10).map((client) => (
                            <img src={client.attributes.url_video} alt=""/>
                        ))}
                    </div>
                </div>
            </div>

            <div className="slider-mobile py-12">
                <h2 className="text-title-49 pl-12 mb-16 font-bold">
                    Nos clients<span>.</span>
                </h2>
                <div className="slider-content">
                    {clients?.data.slice(0, 8).map((client) => (
                        <div className="slide-m">
                            <img src={client.attributes.url_video} alt=""/>
                        </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default SliderHome;
