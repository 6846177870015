import {useEffect, useState} from "react";

const BackstagesAgence = () => {
    const [imageUrls, setImageUrls] = useState([]);

    useEffect(() => {
        // Faites votre appel API ici (utilisez fetch ou axios par exemple)
        // Assurez-vous de traiter la réponse JSON
        const apiUrl = "https://back.focus-production.fr/api/backstage-videos?populate=*";

        fetch(apiUrl,{
            headers: {
            Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
        }
    })
            .then(response => response.json())
            .then(data => setImageUrls(data))
            .catch(error => console.error('Erreur lors de la récupération des données :', error));
    }, []);
    const image1Url = imageUrls.data?.attributes?.image_1;
    const image2Url = imageUrls.data?.attributes?.image_2;
    const image3Url = imageUrls.data?.attributes?.image_3;
    const image4Url = imageUrls.data?.attributes?.image_4;
    const image5Url = imageUrls.data?.attributes?.image_5;
    const image6Url = imageUrls.data?.attributes?.image_6;
    return(
            <div className="backstages mb-20">
                <h2 className="backstages-title  min-[320px]:text-36 xl:text-5xl font-bold min-[320px]:px-0">Les backstages<span>.</span></h2>

                <div className="backstages-grid min-[320px]:mt-0 xl:mt-9">
                    <div className="ct-backstage-l1 flex justify-between min-[320px]:mb-4 xl:mb-8 min-[320px]:flex-col xl:flex-row min-[320px]:gap-4 xl:gap-0">
                            <div className="backstages-img mr-8">
                                {image1Url ? (
                                    <img src={image1Url} alt="Image 6" style={{ maxWidth: '100%' }} />
                                ) : null}
                            </div>
                        <div className="backstages-img mr-8">
                            {image2Url ? (
                                <img src={image2Url} alt="Image 6" style={{ maxWidth: '100%' }} />
                            ) : null}
                        </div>
                        <div className="backstages-img red-background w-1/3 flex flex-col justify-between min-[320px]:p-2 xl:p-6">
                            <p className="min-[320px]:text-left xl:text-center min-[320px]:pb-12 xl:pb-0">On regarde le résultat ?</p>
                            <a href="/realisations" className="self-end text-f-22 red-background-link">Voir les réalisations</a>
                        </div>
                    </div>


                    <div className="scd-sec flex justify-between min-[320px]:flex-col xl:flex-row min-[320px]:gap-4 xl:gap-0">
                            <div className="backstages-img w-full">
                                {image3Url ? (
                                    <img src={image3Url} alt="Image 6" style={{ maxWidth: '100%' }} />
                                ) : null}
                            </div>
                        <div className="backstages-img w-full">
                            {image4Url ? (
                                <img src={image4Url} alt="Image 6" style={{ maxWidth: '100%' }} />
                            ) : null}
                            </div>
                    </div>
                    <div className="scd-sec flex justify-between min-[320px]:flex-col xl:flex-row min-[320px]:gap-4 xl:gap-0 xl:mt-8">
                            <div className="backstages-img w-full">
                                {image5Url ? (
                                    <img src={image5Url} alt="Image 6" style={{ maxWidth: '100%' }} />
                                ) : null}
                            </div>
                        <div className="backstages-img w-full">
                            {image6Url ? (
                                <img src={image6Url} alt="Image 6" style={{ maxWidth: '100%' }} />
                            ) : null}
                            </div>
                    </div>




                </div>
            </div>
    )
}

export default BackstagesAgence;