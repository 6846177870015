import HomeAgence from "../components/Home/HomeAgence";
import PhotoVideo from "../components/Home/PhotoVideo";
import ProjetsHome from "../components/Home/ProjetsHome";
import FormHome from "../components/FormHome";
import SliderHome from "../components/SliderHome";
import Avis from "../components/Home/Avis";
import React, {useEffect, useRef, useState} from "react";

function Home() {
    const [bgVideo, setBgVideo] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        fetch("https://back.focus-production.fr/api/background-videos?populate=*", {
            headers: {
                Authorization: "Bearer 8347c4958f9b5905480c0925ef109f50ae10bd2321907f6ec0633d7f3beac8d9b91120b662ee17e2bed2b2c4a3c497c4a99f02ddc6bd00b08961a9f2d66603b090d174a2275799a4c064f668f3a14a5e7b2e2034f8fd70682ef5bc272415072e7292e8afa081fbea201ea222601abc09e7ee5a8c1c0b0c56af08f268214a08a4"
            }
        })
            .then((res) => res.json())
            .then((data) => setBgVideo(data));
    }, []);

    const handleProjectClick = () => {
        setShowModal(true);
        if (videoRef.current) {
            videoRef.current.muted = false;
        }
    };

    const handleCloseModal = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);

            // Pause the video when the modal is closed
            if (videoRef.current) {
                videoRef.current.pause();
            }
        }
    };
    const handleModalCloseButtonClick = () => {
        setShowModal(false);

        // Pause the video when the modal close button is clicked
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };



    return (
        <>
            <div className="ct-header">
                <div className="container-home-header h-screen flex justify-center items-center flex-col">
                    <h1 className="xl:text-8xl font-bold min-[320px]:text-f-40">Focus Production</h1>
                    <hn className="xl:text-5xl min-[320px]:text-xl">Capturer <span>l'instant</span>, partager <span>l'émotion</span></hn>
                    <a className="mt-8 xl:mt-32 text-2xl btn-arrow -ml-11" onClick={handleProjectClick}>Voir le showreel</a>

                    <div className={`showreel-modal ${showModal ? 'open' : ''}`} onClick={handleCloseModal}>
                        <div className="showreel-modal-content" ref={modalRef}>
                            <button onClick={handleModalCloseButtonClick} className="showreel-modal-close"><img src={require('../img/close-icon.png')}/></button>
                            {bgVideo?.data?.slice(0, 1).map((video) => (
                                <video controls autoPlay muted ref={videoRef} key={video.id}>
                                    <source src={video.attributes.url_video} type="video/mp4" />
                                </video>
                            ))}
                        </div>
                    </div>
                </div>


                <div className="video-bg">
                    {bgVideo?.data?.slice(0, 1).map((video) => (
                        <video autoPlay muted loop key={video.id}>
                            <source src={video.attributes.url_video} type="video/mp4" />
                        </video>
                    ))}
                </div>
            </div>


            <HomeAgence/>

            <PhotoVideo/>

            <SliderHome/>

            <ProjetsHome/>

            <div className="pb-32">
                <Avis/>
            </div>

            <FormHome/>
        </>
    );
}

export default Home;
