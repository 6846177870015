const HomeAgence = () => {
    return(
        <div className="container-home-agence xl:px-52 xl:py-28 min-[320px]:px-4 min-[320px]:flex-col min-[320px]:py-8 flex xl:flex-row">
            <div className="title-agence xl:w-1/2 min-[320px]:w-full mr-8">
                <h2 className="text-5xl font-bold xl:text-end min-[320px]:text-start min-[320px]:mb-9 xl:mb-0">L'agence<span>.</span></h2>
            </div>
            <div className="text-agence xl:w-10/12 min-[320px]:w-full">
                <p className="text-lg mb-8">Focus production est une agence bordelaise spécialisée dans la production audiovisuelle et dans la photographie. Nous accompagnons les entreprises dans leur communication photo et vidéo. De l'idée à la réalisation, Focus production réalise vos contenus audiovisuelles sur mesure dans toute la France. </p>
                <a className="text-2xl btn-arrow" href="/agence">Découvrir l'agence</a>
            </div>
        </div>
    )
}
export default HomeAgence